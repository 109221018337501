
import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useSubscription } from '@apollo/client/react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './DataView.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { SessionContext } from '../session';
import { transformInitData, transformMetricsData } from '../utils/agGridConverters';
import { SUB_MONITOR, QUERY_MONITOR } from '../gql/gqlQuerys';
import { MINUTES, fetchPolicy } from '../utils/constants';

const stateDateFilter = new Date(new Date().getTime() - (MINUTES * 2));

function DataView({ selected }) {

    const modulesSelect = JSON.parse(selected.model.modules_select);
    const monitor = selected.model.monitor_id;
    const utarstate = "offline";
    const initDateFilter = new Date(new Date().getTime() - (MINUTES * 2));

    const gqlOptSub = { variables: { dateFilter: initDateFilter, dateOfflineFilter: stateDateFilter, utarstate, modulesSelect, monitor }, fetchPolicy, };

    const gqlParams = { variables: { modulesSelect, monitor }, fetchPolicy, };
    const [gqlParamsSub, setGqlParamsSub] = useState(gqlOptSub);

    const queryInit = useQuery(QUERY_MONITOR, gqlParams);
    const { data, error, loading, variables } = useSubscription(SUB_MONITOR, gqlParamsSub);
    const [gridInit, setGridInit] = useState(false);
    const [subPerMinute, setSubPerMinute] = useState(1);
    const [currentMintute, setCurrentMintute] = useState(1);
    const [countMintute, setCountMintute] = useState(0);
    const [lastReceived, setLastReceived] = useState(5000);
    const [updateDateFilter, setUpdateDateFilter] = useState(false);
    const {
        session: { gridApi, columnApi, gridList },
        setGridApi,
        setColumnApi,
        setGridList
    } = useContext(SessionContext);
    const [groups, setGroups] = useState({});
    const [delayDraw, setDelayDraw] = useState(Date.now());

    selected.gridOptions.onGridReady = (params) => {
        if (gridApi === null) {
            setGridApi(params.api);
        }
        if (columnApi === null) {
            setColumnApi(params.columnApi);
        }
    };

    const applyGridData = (data, query) => {
        let lastDt;
        if (gridApi) {
            if (query) {
                const { rows, rowObj, rowGroups } = transformInitData(data, selected.model);
                setGroups(rowGroups);
                if (rows.length > 0) {
                    setGridList(rowObj);
                    if (gridApi) {
                        gridApi.setRowData(rows);
                        setGridApi(gridApi);
                    }
                    if (columnApi) {
                        var allColumnIds = [];
                        columnApi.getColumns().forEach(function (column) {
                            allColumnIds.push(column.colId);
                        });
                        columnApi.autoSizeColumns(allColumnIds, false);
                        setColumnApi(columnApi);
                    }
                }
            } else {
                const localGridList = gridList;
                let rowsToUpdate = [];
                if (localGridList) {
                    const { rows, rowObj, rowGroups, lastDate } = transformMetricsData(data, localGridList, groups, selected.model);
                    if (lastDate) lastDt = lastDate;
                    setGridList(rowObj);
                    setGroups(rowGroups);
                    rowsToUpdate = rows;
                }
                if (gridApi) {
                    //Na versão 30 do Ag Grid o metodo redrawRows não renderiza
                    // o agAnimateShowChangeCellRenderer do cellRenderer. 
                    // na versão mais recente o Ag Grid detecta e recarrega
                    // qualquer dado alterado na lista de registros de forma 
                    // individual quando usado o update do applyTransactionAsync,
                    // não sendo mais necessário o uso do redrawRows
                    gridApi.applyTransactionAsync({ update: rowsToUpdate });
                    if (delayDraw < Date.now()) {
                        setDelayDraw(Date.now() + 2000);
                        if (rowsToUpdate.length > 0) {
                            setTimeout(() => {
                                gridApi.redrawRows();
                                setGridApi(gridApi);
                            }, "1800");
                        }
                    }
                    setGridApi(gridApi);
                }
            }
            setGridApi(gridApi);
        }
        if (columnApi && query) {
            const allColumnIds = [];
            columnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            columnApi.autoSizeColumns(allColumnIds, false);
            setColumnApi(columnApi);
        }
        return lastDt;
    }

    const updateCount = () => {
        if (currentMintute !== new Date().getMinutes()) {
            setSubPerMinute(countMintute);
            setCurrentMintute(new Date().getMinutes());
            setCountMintute(1);
            setUpdateDateFilter(true);
        } else {
            setCountMintute(countMintute + 1);
        }
    }

    const updateGqlFilter = (newLastDt) => {
        const newFilterDateRange = new Date(new Date().getTime(newLastDt) - (MINUTES * 1.1));
        if (updateDateFilter && subPerMinute > 0 && !loading) {
            if (newLastDt) {
                gqlOptSub.variables.dateFilter = newFilterDateRange;
            }
            setUpdateDateFilter(false);
            setGqlParamsSub(gqlOptSub);
        }
    }

    useEffect(() => {
        if (error) console.log("Hasura Subscription info:", { error, loading, variables });
        if (loading) console.log("MONITOR LOADING ...", variables);
        selected.gridOptions.onGridReady = (params) => {
            setGridApi(params.api);
            setColumnApi(params.columnApi);
        };
        if (queryInit.data && !gridInit) {
            applyGridData(queryInit.data.data, true);
            setGridInit(true);
        }
        if (data && data?.data && data.data.length && !loading && gridInit) {
            applyGridData(data.data, false);
            const lastDtTelemetry = new Date();
            console.log("[data/lastReceived]", data.data.length, lastReceived);
            if (data.data.length === lastReceived && data.data[0].device_state !== "offline") {
                setUpdateDateFilter(true);
            }
            updateGqlFilter(lastDtTelemetry);
            setLastReceived(data.data.length);
        }
        updateCount();
    }, [data, error, selected, loading]);

    return (
        <div style={{ width: '100%', height: 'calc(100vh - 70px)' }}>
            <div className="ag-theme-balham" style={{ width: "100%", height: "100%" }}>
                <AgGridReact gridOptions={selected.gridOptions} rowHeight={30} />
            </div>
        </div>
    );
}

export default React.memo(DataView);