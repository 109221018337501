
import { MainIcon } from './components/MainIcon';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import { UserProfileLabel } from './UserProfileLabel';

export const NavbarMonitor = ({ profileInfo, reset, title, model, exit }) => {
    return (
        <Navbar bg="light" variant="light" expand="md"
            className="bg-light justify-content-between">
            <Navbar.Brand href="#home">
                <Button onClick={() => reset()} variant={"light"}
                    size="sm" style={{ marginTop: -8 }} >
                    <MainIcon name="backNavBar" />
                </Button>
                <b> {title} </b>
                <font style={{ color: model?.color, marginLeft: 10, }} >
                    {model?.name}
                </font>

            </Navbar.Brand>
            <Navbar.Brand href="#home">
                <UserProfileLabel profileInfo={profileInfo} exit={exit} />
            </Navbar.Brand>
        </Navbar>
    );
};