
import Button from 'react-bootstrap/Button';
import { MainIcon } from './components/MainIcon';

export const UserProfileLabel = ({ profileInfo, exit }) => {
    return (
        <div>
            <b style={styles.nameProfile} >
                {profileInfo.name}
                <Button variant="light" title="Sair" onClick={exit}
                    size="sm" style={styles.exitBtn} >
                    <MainIcon name="logout" />
                </Button>
            </b>
            <div style={styles.emailProfile} >
                {profileInfo.email}
            </div>
        </div>
    )
};

const styles = {
    "emailProfile": {
        marginTop: -15,
        fontSize: 12,
        color: 'gray'
    },
    "nameProfile": {
        fontSize: 16,
        color: 'gray'
    },
    "exitBtn": {
        marginLeft: 20,
        marginTop: 15,
    }
}