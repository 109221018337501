export const MenuTitle = ({ totalItems, select, models }) => {

    if (totalItems === 1) {
        select(models[0].key);
    }

    const renderStyle = {
        fontColor: totalItems > 0 ? 'black' : 'silver',
        text: totalItems > 0 ? 'Selecione o monitor' : 'Carregando perfil...'
    }
    return (
        <>
            <h4 style={{ color: renderStyle.fontColor }} >
                {renderStyle.text}
            </h4>
            <br />
        </>
    )
};