import { columnDefsModel } from './columnDefsModel'; 
import { getRowClass } from './getRowClass';

export const gridOptionsModel = (dataViewModel) => {
    const gHeaderName = dataViewModel?.group_header_name ? dataViewModel.group_header_name : dataViewModel.group_id;
    const gWidth = dataViewModel?.group_width ? dataViewModel.group_width : 340;
    return {
        asyncTransactionWaitMillis: 500,
        animateRows: true,
        autoGroupColumnDef: {
            headerName: gHeaderName,
            width: gWidth,
            maxWidth: gWidth,
        },
        columnDefs: columnDefsModel(dataViewModel),
        groupDefaultExpanded: dataViewModel.groupDefaultExpanded,
        rowGroupPanelShow: 'always',
        suppressMakeColumnVisibleAfterUnGroup: false,
        suppressDragLeaveHidesColumns: false,
        showOpenedGroup: false,
        getRowClass: getRowClass,
        defaultColDef: {
            resizable: true,
            flex: 1
        },
        suppressRowHoverHighlight: true
    }

};