import { getGroupItems } from '../utils/dataCheck';
import { TagIconAgGrid } from './TagIconAgGrid';
// Componente AgGrid p/ exibição de dados do Utar
export function DeviceInfoAgGrid(params) {
    const group = getGroupItems(params);
    if (group && typeof group.deviceId !== "string") {
        console.error("[DeviceInfoAgGrid] null", group.deviceId);
        return null;
    }
    const hint = 'Utar: ' + group.deviceId + '\n' + 
                'Versão: ' + group.version + '\n' + 
                'State: ' + group.deviceState + '\n' + 
                'Recebido: ' + group.deviceStateAt.toLocaleString("pt-BR") + '\n' + 
                'Inicializações: ' + group.startupCount + '\n' + 
                'Vezes offline: ' + group.offlineCount + '\n';
    return TagIconAgGrid("info", hint);
}
