import { TagAlert } from './TagAlert';
import { isValueGroup,  isValueLine, isAboveMax, isBelowMin, 
        checkControlOff, getGroupItems } from '../utils/dataCheck';

export function MonitoringTagAgGrid(params, key) {
    const row = { 
        "group": getGroupItems(params), 
        "value": null,
        "ref_values": null,
        "reference":null,
        "color":null
    };
    if (row.group === null) {
        return null;
    }
    row.ref_values = row.group.references[key];

    if (isValueGroup(params)) {
        row.value = params.value.value;
    }

    if (isValueLine(params) && row.ref_values) {
        row.value = params.value;  
    }

    if (isAboveMax(row.group, row.value, key)) {
        row.reference = row.ref_values.max;
        row.color = row.group.colors_palette?.max;
    }

    if (isBelowMin(row.group, row.value, key)) {
        row.reference = row.ref_values.min;
        row.color = row.group.colors_palette?.min;
    }

    if (checkControlOff(row.group)) {
        row.reference = row.ref_values.max;
        row.color = row.group.colors_palette?.main_reference_control_off;
    }

    if(row.reference && row.color){
        return {
            component: () => <TagAlert value={row.value} target={row.ref_values}
                                max={row.reference} tag={key} color={row.color} />
        }
    }

}
