import {
    dateExtractor, referencesExtractor, valueExtractor, warningExtractor, identificationExtractor,
    groupExtractor,
    idExtractor
} from './dataExtractors';
import { reloadItemsGroup, setDeviceState, objToArray } from './dataConverters';
import { compareId } from './sort';

export function transformInitData(rawData, selectedModel) {
    const globalObj = {};
    const globalGroups = {};
    const lastDate = { "value": null };
    for (const item of rawData) {
        if (item?.identification !== undefined) {
            const ident = idExtractor(item, selectedModel);
            if (ident) {
                const { linesId } = ident;
                for (const lineId of linesId) {
                    const id = lineId.id;
                    if (lineId.groupId) {
                        if (lineId.isLine) {
                            if (globalObj[id] === undefined) {
                                globalObj[id] = { id, "warning": [], "group": {} };
                            }
                            identificationExtractor(item.identification, globalObj[id]);

                            if (lineId.isLine) globalObj[id]['zona'] = lineId.zone;

                            if (globalObj[id][selectedModel.group_id]) {
                                groupExtractor(globalGroups, lineId.groupId, item, selectedModel, lineId.zone);
                                warningExtractor(item, globalObj[id], globalGroups[lineId.groupId], id);
                                dateExtractor(item, globalObj[id], lastDate);
                                referencesExtractor(item, globalObj[id], selectedModel);
                                valueExtractor(item, globalObj[id], selectedModel);
                                if (globalGroups[lineId.groupId] && lastDate.value) {
                                    globalGroups[lineId.groupId]['lastValueAt'] = lastDate.value;
                                }
                            }

                        } else {
                            groupExtractor(globalGroups, lineId.groupId, item, selectedModel, lineId.zone);
                        }
                    } else {
                        console.log("groupId null", lineId);
                    }
                }
            }
        }
    }
    const rowData = reloadItemsGroup(globalObj, globalGroups, selectedModel);
    rowData.sort(compareId);
    console.log("rowData init", rowData);
    return { "rows": rowData, "rowObj": globalObj, "rowGroups": globalGroups };
}

export function transformMetricsData(rawData, globalObj, globalGroups, selectedModel) {
    const rowsToUpdate = {};
    const verifyWarning = {};
    const lastDate = { "value": null };
    for (const item of rawData) {
        if (item?.identification !== undefined) {
            const ident = idExtractor(item, selectedModel);
            if (ident) {
                const { linesId } = ident;
                for (const lineId of linesId) {
                    const id = lineId.id;
                    if (lineId.isLine) {
                        if (globalObj[id]) {
                            valueExtractor(item, globalObj[id], selectedModel);
                            setDeviceState(item.device_state, globalObj[id]);
                            referencesExtractor(item, globalObj[id], selectedModel);
                            dateExtractor(item, globalObj[id], lastDate);
                            rowsToUpdate[id] = globalObj[id];
                            groupExtractor(globalGroups, lineId.groupId, item, selectedModel, lineId.zone);
                            if (globalGroups[lineId.groupId] && lastDate.value) {
                                globalGroups[lineId.groupId]['lastValueAt'] = lastDate.value;
                            }
                            // Não fazer check de warning em linha já tratada
                            if (!verifyWarning[id]) {
                                verifyWarning[lineId.groupId] = true;
                                warningExtractor(item, globalObj[id], globalGroups[lineId.groupId], id);
                            }
                        }
                    } else {
                        groupExtractor(globalGroups, lineId.groupId, item, selectedModel, lineId.zone);
                    }
                }
            }
        }
    }
    const rowData = objToArray(globalObj);
    console.log("[globalGroups]", globalGroups);
    console.log("[rowData]", rowData);
    return { "rows": rowData, "rowObj": globalObj, "rowGroups": globalGroups, "lastDate": lastDate.value };
}