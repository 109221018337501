import Badge from 'react-bootstrap/Badge';
import { TagIcon } from './TagIcon';

export function HideTag({ title }) {
    return (
        <h6>
            <Badge variant="secondary" title={title}>
                <TagIcon name="hide" />
            </Badge>
        </h6>)
}