
const color ={ 
    "pause": "silver",
    "pause_group": "#f8f9fa",
    "play": "blue",
    "play_group": "slateblue"
};
const icon={ 
    "pause": "pause",
    "pause_group": "pause",
    "play": "play",
    "play_group": "play"
};

export function ValveTemplate(action, label, info) {
    return { 
        "id": String(label), 
        "color": color[action], 
        "icon": icon[action], 
        "title": info 
    };
}
