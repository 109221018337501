import { TagAlert } from "./TagAlert";
import { isObject } from "../utils/dataCheck";
import { ValveTemplate } from "./ValveTemplate";
import { isActive } from "../utils/dataCheck";
import { EmptyCellAgGrid } from "./EmptyCellAgGrid";

export function ValveActionAgGrid(statesArr) {
    if (isObject(statesArr)) {

        const state = { "state": "", "valve": "" }
        if (statesArr.length > 0) {
            state.state = "pause";
            for (const [key, stateValve] of statesArr.entries()) {
                if (isActive(stateValve)) {
                    state.state = "play";
                    state.valve = key;
                }
            }
        } else {
            for (const key in statesArr) {
                if (isActive(statesArr[key])) {
                    state.state = "play_group";
                    state.valve = key;
                }
            }
        }
        if (state.state) {
            const valve = ValveTemplate(state.state, state.valve, "");
            return {
                component: () => <TagAlert value={valve.id} msg={valve.title} tag={valve.icon} color={valve.color} />
            }
        } else {
            return EmptyCellAgGrid;
        }

    }
}
