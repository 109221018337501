import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { MenuTitle } from './MenuTitle';

export const MenuModels = ({select, models, time}) => {
    return (
        <div style={styles.authFormView} >

            <MenuTitle totalItems={models.length} select={select} models={models} />

            <ButtonGroup vertical>
                {(models.length === 0 && time > 0) &&
                    <Spinner animation="border" variant="secondary" />
                }

                {models.map((btn) =>
                    <Button onClick={() => select(btn.key)} variant={"outline-primary"} key={btn.key}>
                        {btn.label}
                    </Button>)}

            </ButtonGroup>

        </div>
    )
};

const styles = {
    "authFormView": {
        height: '100vh',
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center"
    }
}