
import Button from 'react-bootstrap/Button';
import { MainIcon } from './components/MainIcon';

export const AuthForm = ({authenticator, title}) => {
    return (
        <div style={styles.authFormView} >
            <h3>{title}</h3>
            <br />
            <Button variant="outline-primary" onClick={authenticator}>
                <MainIcon name="google" />
                Entrar com sua conta Google
            </Button>
        </div>
    )
};

const styles = {
    "authFormView": {
        height: '100vh',
        display: "flex",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center"
    }
}
