import React, { useState, useEffect } from 'react';
import DataView from "./components/DataView";
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { MONITOR_TITLE, modelsApi } from './utils/constants';
import { Authorization } from './services/Authorization';
import { Models } from './services/Models';
import { gridOptionsModel } from './configs/dataViewOptions';
import { MenuModels } from './MenuModels';
import { AuthForm } from './AuthForm';
import { NavbarMonitor } from './NavbarMonitor';
import { isAuthorized, isSelectedModel } from './utils/dataCheck';


function Auth() {

    const [profile, setProfile] = useState();
    const [model, setModel] = useState();
    const [columnsModel, setColumnsModel] = useState();
    const [menu, setMenu] = useState([]);
    const [gridOptions, setGridOptions] = useState();
    const [apiTime, setApiTime] = useState(0);

    useEffect(() => {
        return () => true;
    }, [model, menu]);

    const login = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            const authorization = new Authorization(codeResponse);
            const responseProfile = await authorization.getProfile();
            setProfile(responseProfile);
            setApiTime(Date.now());
            const models = new Models(responseProfile.email, modelsApi);
            const responseModels = await models.getModels();
            if (responseModels?.models) {
                setColumnsModel(responseModels.models);
                let newMenu = []

                for (const key in responseModels.models) {
                    newMenu.push({
                        key,
                        "label": key + " - " + responseModels.models[key].name
                    });
                }

                setMenu(newMenu);
            }
        },
        onError: (error) => console.error('Login Failed:', error)
    });

    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    const onModel = (selected) => {
        setGridOptions(undefined);
        const dataViewModel = columnsModel[selected];
        setModel(dataViewModel);
        const dataViewgridOptions = gridOptionsModel(dataViewModel);
        setGridOptions(dataViewgridOptions);
    };

    const resetModel = () => {
        setGridOptions(undefined);
        setModel(undefined);
    };

    let mainView;
    if (isAuthorized(profile)) {
        if (isSelectedModel(model, gridOptions)) {
            mainView = <>
                <NavbarMonitor profileInfo={profile} reset={resetModel}
                    model={model} exit={logOut} title={MONITOR_TITLE} />
                <DataView selected={{ "model": model, "gridOptions": gridOptions }} />
            </>
        } else {
            mainView = <MenuModels select={onModel} models={menu} time={apiTime} />;
        }
    } else {
        mainView = <AuthForm authenticator={login} title={MONITOR_TITLE} />;
    }

    return (mainView)
}

export default Auth;