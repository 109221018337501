import React from 'react';
import ReactDOM from 'react-dom';
import Auth from './Auth';
import {SessionContextProvider} from "./session";
import { ApolloProvider } from '@apollo/client/react';
import createApolloClient from './gql/client';

import { GoogleOAuthProvider } from '@react-oauth/google';

const client = createApolloClient();

ReactDOM.render(
  <GoogleOAuthProvider clientId="677152188367-2rlrkhtqvs737vbn8662ro7p0k5glcmc.apps.googleusercontent.com">
    <React.StrictMode>
      <SessionContextProvider>
        <ApolloProvider client={client}>
          <Auth />
        </ApolloProvider>
      </SessionContextProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);