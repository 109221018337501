import { EngineTag } from './EngineTag';
import { isActive } from '../utils/dataCheck';

export function EngineTagAgGrid(group) {
    const state = isActive(group?.controleMotor) ? engineControl[1] : engineControl[0];
    return {
        component: () => <EngineTag state={state} />
    }
}

const styles = {
    "engineOn": { padding: 6, borderColor: 'white', borderWidth: 1, borderStyle: 'solid', letterSpacing: 1, fontSize: 12 },
    "engineOff": { padding: 6, borderColor: 'white', borderWidth: 1, borderStyle: 'solid', letterSpacing: 0, fontSize: 10, color: '#f5f5f5', fontWeight: '400' }
};

const engineControl = [
    { "variant": "secondary", "tag": "Desligado", "style": styles.engineOff },
    { "variant": "success", "tag": "Ligado", "style": styles.engineOn }
];