
export function isLineWarning(line) {
    line.group.warningMap[line.id] && console.log("[isLineWarning]", line.id, line.group.warningMap[line.id]);
    return line.group.warningMap[line.id];
}

export function isGroupWarning(group) {
    const warnings = [];
    for (let id in group.warningMap){
        if(group.warningMap[id]){
            const warning =  group.warningMap[id].module_id.split("_");
            const module = warning.length > 1 ? warning[1] : "--";
            warnings.push(module);
        }
    }
    if(warnings.length){
        return warnings;
    } else {
        return null;
    }
}

export function isOffiline(group) {
    return (group?.deviceState === "offline");
}

export function isLine(params) {
    if (params?.data) {
        return params.data;
    } else {
        return false;
    }
}

export function isGroup(params) {
    return (!params?.value);
}

export function isAuthorized(profile) {
    return (profile && profile?.email);
}

export function isSelectedModel(model, gridOptions) {
    if (!model && !gridOptions) {
        return false;
    } else {
        return true;
    }
}

export function isObject(value) {
    return (value && typeof value === "object");
}

export function isNumber(value) {
    return (value && typeof value === "number");
}

export function isActive(value) {
    return (value && typeof value === "number" && value === 1);
}

export function getGroupItems(params) {
    if (params?.node && params.node?.allLeafChildren
        && params.node?.allLeafChildren.length
        && params.node?.allLeafChildren[0]?.data
        && params.node?.allLeafChildren[0].data?.group) {
        return params.node?.allLeafChildren[0].data.group;
    } else {
        if(params?.data && params.data?.group){
            return params.data.group;
        }else{
            return null;
        }
    }
}

export function isSubGroup(params, groupRef) {
    if (!params.data && params?.node && params.node?.field 
            && params.node.field === "zona" && params.node.key !== "geral"
            && params.node?.allLeafChildren[0].data.group[groupRef]) {
        return { "value": params.node?.allLeafChildren[0].data.group[groupRef][params.node.key] };
    } else {
        return false;
    }
}

export function isValueGroup(params) {
    return (params?.value && typeof params.value?.value === "number");
}

export function isValueLine(params) {
    return (params?.value && typeof params.value === "number" && params.data.group?.references);
}

export function checkControlOff(group) {
    return (group.controleSistema === 0 && group.colors_palette?.main_reference_control_off);
}

export function isAboveMax(group, value, key) {
    return (group.references !== undefined
            && group.references[key] !== undefined
            && group?.references[key]?.max !== null
            && value > group?.references[key]?.max);
}

export function isBelowMin(group, value, key) {
    return (group.references !== undefined
            && group.references[key] !== undefined
            && group?.references[key]?.min !== null
            && value < group?.references[key]?.min);
}
