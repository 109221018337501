import { TagIcon } from "./TagIcon"

export function TagIconAgGrid(tag, msg = "") {
    return {
        component: () => (
            // Msg do hint disponível por click para uso mobile
            <div style={{ paddingBottom: 5, }} title={msg}  onClick={()=>alert(msg)}  >
                <TagIcon name={tag} />
            </div>)
    }
}